import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { MD5 } from 'crypto-js';
import Swal from 'sweetalert2';

import naught from '../../assets/images/nps/0.png';
import one from '../../assets/images/nps/1.png';
import two from '../../assets/images/nps/2.png';
import three from '../../assets/images/nps/3.png';
import four from '../../assets/images/nps/4.png';
import five from '../../assets/images/nps/5.png';
import six from '../../assets/images/nps/6.png';
import seven from '../../assets/images/nps/7.png';
import eight from '../../assets/images/nps/8.png';
import nine from '../../assets/images/nps/9.png';
import ten from '../../assets/images/nps/10.png';

const NPSInsider = () => {
    const [text, setText] = useState('');
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [nota, setNota] = useState(null);
    const [hub, setHub] = useState('');

    const [canRender, setCanRender] = useState(false);

    const [modalStep, setModalStep] = useState(1);

    const evalList = [
        {
            content: '0',
            image: naught,
        },
        {
            content: '1',
            image: one,
        },
        {
            content: '2',
            image: two,
        },
        {
            content: '3',
            image: three,
        },
        {
            content: '4',
            image: four,
        },
        {
            content: '5',
            image: five,
        },
        {
            content: '6',
            image: six,
        },
        {
            content: '7',
            image: seven,
        },
        {
            content: '8',
            image: eight,
        },
        {
            content: '9',
            image: nine,
        },
        {
            content: '10',
            image: ten,
        },
    ];

    useEffect(() => {
        const handleLoad = () => {
            if (window.location.hostname !== 'cs.corebiz.ag') return (window.location = 'https://www.corebiz.ag');
            if (!window.location.search) return (window.location = 'https://www.corebiz.ag');

            const urlParams = new URLSearchParams(window.location.search);

            const var_nome = urlParams.get('name').replace(/%20/g, ' ');
            const var_email = urlParams.get('email').replace(/%20/g, ' ');
            const var_nota = urlParams.get('eval').replace(/%20/g, ' ');
            const var_hub = urlParams.get('hub').replace(/%20/g, ' ');

            setNome(var_nome);
            setEmail(var_email);
            setNota(var_nota);
            setHub(var_hub);

            window.mixpanel.init('6d2394835ba97b5f734d521b41406d61', { debug: true });
            window.mixpanel.identify(var_email);
            window.mixpanel.people.set({
                $name: var_nome,
                $email: var_email,
            });

            setCanRender(true);
        };

        if (document.readyState === 'complete') {
            handleLoad();
        } else {
            window.addEventListener('load', handleLoad);
        }

        return () => {
            if (document.readyState !== 'complete') {
                window.removeEventListener('load', handleLoad);
            }
        };
    }, []);

    useEffect(() => {
        if (nota !== null) {
            sendEvaluate(nota);
        }
    }, [nota]);

    const sendEvaluate = (evaluate) => {
        Swal.fire({
            title: 'Are you sure?',
            showCancelButton: true,
            confirmButtonText: 'Yes, send it!',
            confirmButtonColor: '#1273e6',
            cancelButtonColor: '#d33',
        }).then((result) => {
            if (result.isConfirmed) {
                const cookieName = MD5(`save_${nome + email}`).toString();

                if (Cookies.get(cookieName)) {
                    setModalStep(2);
                    return;
                }

                const emailsTester = ['gustavo.henrique@corebiz.ag'];
                const eventName = emailsTester.includes(email) ? '[Test] NPS Insider' : 'NPS Insider';

                if (!emailsTester.includes(email)) {
                    trackEventIntercom();
                }

                window.mixpanel.track(eventName, {
                    name: nome,
                    email_address: email,
                    evaluation: parseInt(evaluate),
                    hub: hub,
                });

                setCanRender(false);

                setTimeout(() => {
                    setModalStep(2);
                    setCanRender(true);

                    Cookies.set(cookieName, true, { expires: 2 });
                }, 2000);
            }
        });
    };

    const trackEventIntercom = () => {
        window.Intercom('boot', {
            api_base: 'https://api-iam.intercom.io',
            app_id: window.INTERCOM_APP_ID,
        });

        window.Intercom('trackEvent', 'nps-insider');
    };

    const sendForm = (e) => {
        e.preventDefault();

        if (text) {
            window.mixpanel.track('NPS Insider - Reason', {
                reason: text,
            });
        }

        setModalStep(3);
    };

    return (
        <>
            {canRender && (
                <>
                    <form className={modalStep === 1 ? 'nps_form active' : 'nps_form'}>
                        <img src={require('../../assets/images/logo.png')} alt="Logo" className="logo" />
                        <p className="question">Em uma escala de 0 a 10, qual é a probabilidade de você recomendar o Corebiz Insider a um amigo ou colega de trabalho?</p>
                        <div className="nps">
                            {evalList.map((item, index) => (
                                <div className="nps_item" key={index} onClick={() => sendEvaluate(item.content)}>
                                    <img src={item.image} alt={item.content} />
                                </div>
                            ))}
                        </div>
                    </form>

                    <form className={modalStep === 2 ? 'nps_form active' : 'nps_form'} onSubmit={(e) => sendForm(e)}>
                        <img src={require('../../assets/images/logo.png')} alt="Logo" />
                        <p className="message">Muito Obrigado por responder a primeira etapa da nossa pesquisa.</p>
                        <p className="question">
                            Gostaria de deixar algum comentário?
                            <span className="disclaimer">(Opcional)</span>
                        </p>
                        <textarea rows="5" onInput={(e) => setText(e.target.value)} value={text}></textarea>
                        <button>Enviar</button>
                    </form>

                    <div className={modalStep === 3 ? 'nps_success active' : 'nps_success'}>
                        <img src={require('../../assets/images/logo-large.png')} alt="Logo" />
                        <span className="border"></span>
                        <h2>Sucesso!</h2>
                        <p>Seu comentário foi enviado com sucesso!</p>
                    </div>
                </>
            )}

            {!canRender && (
                <div className="nps_loading active">
                    <img src={require('../../assets/images/logo.png')} alt="Logo" />
                    <span className="border"></span>
                    <div className="loading">
                        <img src={require('../../assets/images/loading.gif')} alt="Loading" />
                    </div>
                </div>
            )}
        </>
    );
};

export default NPSInsider;
