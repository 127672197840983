export const formatDate = (date, separator, formatType) => {
    if (!date) return '';

    if (date.indexOf(':') === -1 && date.indexOf('/') === -1) {
        date = date + ' 00:00:00';
    }

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const input = new Date(date);
    const getYear = input.getFullYear();
    const getMonth = input.getMonth() + 1;
    const getDay = input.getDate();

    let format;
    switch (formatType) {
        case 'BR MONTH':
            format = months[getMonth - 1] + ' ' + (getDay > 9 ? getDay : '0' + getDay) + separator + ' ' + getYear;
            break;
        case 'EN MONTH':
            format = getYear + separator + ' ' + months[getMonth - 1] + ' ' + (getDay > 9 ? getDay : '0' + getDay);
            break;
        case 'BR':
            format = (getDay > 9 ? getDay : '0' + getDay) + separator + (getMonth > 9 ? getMonth : '0' + getMonth) + separator + getYear;
            break;
        default:
            format = getYear + separator + (getMonth > 9 ? getMonth : '0' + getMonth) + separator + (getDay > 9 ? getDay : '0' + getDay);
            break;
    }

    return format;
};

export const toCapitalize = (str) => {
    let content = '';
    let split = str.split(' ');
    let count;
    let stringDReplace = ['da', 'de', 'di', 'do', 'du'];
    split.map((data, index) => {
        if (data) {
            count = index;
        }
    });
    split.map((data, index) => {
        if (data) {
            if (stringDReplace.includes(data.toLowerCase())) {
                content = content + data.toLowerCase();
            } else {
                content = content + data.charAt(0).toUpperCase() + data.slice(1).toLowerCase();
            }
            if (index !== count) {
                content = content + ' ';
            }
        }
    });
    return content;
};
