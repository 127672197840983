import { useEffect, useState } from 'react';
import './index.scss';
import Cookies from 'js-cookie';
import axios from 'axios';
import { MD5 } from 'crypto-js';

//? Images
import naught from '../../assets/images/nps/0.png';
import one from '../../assets/images/nps/1.png';
import two from '../../assets/images/nps/2.png';
import three from '../../assets/images/nps/3.png';
import four from '../../assets/images/nps/4.png';
import five from '../../assets/images/nps/5.png';
import six from '../../assets/images/nps/6.png';
import seven from '../../assets/images/nps/7.png';
import eight from '../../assets/images/nps/8.png';
import nine from '../../assets/images/nps/9.png';
import ten from '../../assets/images/nps/10.png';
import Swal from 'sweetalert2';

const NPS = () => {
    const [intercomId, setIntercomId] = useState('');
    const [text, setText] = useState('');
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [empresa, setEmpresa] = useState('');
    const [tier, setTier] = useState('');
    const [nota, setNota] = useState(null);

    const [canRender, setCanRender] = useState(false);

    const [modalStep, setModalStep] = useState(1);

    const evalList = [
        {
            content: '0',
            image: naught,
        },
        {
            content: '1',
            image: one,
        },
        {
            content: '2',
            image: two,
        },
        {
            content: '3',
            image: three,
        },
        {
            content: '4',
            image: four,
        },
        {
            content: '5',
            image: five,
        },
        {
            content: '6',
            image: six,
        },
        {
            content: '7',
            image: seven,
        },
        {
            content: '8',
            image: eight,
        },
        {
            content: '9',
            image: nine,
        },
        {
            content: '10',
            image: ten,
        },
    ];

    useEffect(() => {
        const handleLoad = () => {
            if (window.location.hostname !== 'cs.corebiz.ag') return (window.location = 'https://www.corebiz.ag');
            if (!window.location.search) return (window.location = 'https://www.corebiz.ag');

            const urlParams = new URLSearchParams(window.location.search);

            const var_nome = urlParams.get('name').replace(/%20/g, ' ');
            const var_email = urlParams.get('email').replace(/%20/g, ' ');
            var var_empresa = urlParams.get('company').replace(/%20/g, ' ');
            const var_tier = urlParams.get('tier').replace(/%20/g, ' ');
            const var_nota = urlParams.get('eval');

            //? Only for test
            if (var_empresa.indexOf('Corebiz') > -1) {
                var_empresa = 'Corebiz';
            }

            axios
                .get(`https://cs.corebiz.ag/api/intercom/${var_email}/${var_empresa}`)
                .then((response) => {
                    return response.data;
                })
                .then((data) => {
                    setIntercomId(data.intercom_id);
                    setNome(var_nome);
                    setEmail(var_email);
                    setEmpresa(var_empresa);
                    setTier(var_tier);
                    setNota(var_nota);

                    window.mixpanel.init('1cddc905042359187b1f49200a5ceb90', { debug: true });
                    window.mixpanel.identify(var_email);
                    window.mixpanel.people.set({
                        $name: var_nome,
                        $email: var_email,
                        $client: var_empresa,
                        $tier: var_tier,
                    });

                    setCanRender(true);
                })
                .catch(() => {
                    window.location = 'https://www.corebiz.ag';
                });
        };

        if (document.readyState === 'complete') {
            handleLoad();
        } else {
            window.addEventListener('load', handleLoad);
        }

        return () => {
            if (document.readyState !== 'complete') {
                window.removeEventListener('load', handleLoad);
            }
        };
    }, []);

    useEffect(() => {
        if (nota !== null) {
            sendEvaluate(nota);
        }
    }, [nota]);

    const sendEvaluate = (evaluate) => {
        Swal.fire({
            title: 'Are you sure?',
            showCancelButton: true,
            confirmButtonText: 'Yes, send it!',
            confirmButtonColor: '#1273e6',
            cancelButtonColor: '#d33',
        }).then((result) => {
            if (result.isConfirmed) {
                const cookieName = MD5(`save_${nome + email + empresa}`).toString();

                if (Cookies.get(cookieName)) {
                    setModalStep(2);
                    return;
                }

                const emailsTester = ['gustavo.henrique@corebiz.ag'];
                const eventName = emailsTester.includes(email) ? '[Test] Corebiz NPS' : 'Corebiz NPS';

                if (!emailsTester.includes(email)) {
                    trackEventIntercom();
                }

                window.mixpanel.track(
                    eventName,
                    {
                        email_address: email,
                        client: empresa,
                        tier: tier,
                        evaluation: parseInt(evaluate),
                    },
                    function () {
                        Cookies.set(cookieName, true, { expires: 2 });
                        setModalStep(2);
                    },
                );
            }
        });
    };

    const trackEventIntercom = () => {
        axios.post('https://cs.corebiz.ag/api/trackEvent', {
            id: intercomId,
            eventName: 'nps-response',
        });
    };

    const sendForm = (e) => {
        e.preventDefault();

        if (text) {
            window.mixpanel.track('Corebiz NPS - Reason', {
                reason: text,
            });
        }

        setModalStep(3);
    };

    return (
        <>
            {canRender && (
                <>
                    <form className={modalStep === 1 ? 'nps_form active' : 'nps_form'}>
                        <img src={require('../../assets/images/logo.png')} alt="Logo" className="logo" />
                        <p className="question">On a scale of 0 to 10, how likely are you to recommend Corebiz to a friend or family member?</p>
                        <div className="nps">
                            {evalList.map((item, index) => (
                                <div className="nps_item" key={index} onClick={() => sendEvaluate(item.content)}>
                                    <img src={item.image} alt={item.content} />
                                </div>
                            ))}
                        </div>
                    </form>

                    <form className={modalStep === 2 ? 'nps_form active' : 'nps_form'} onSubmit={(e) => sendForm(e)}>
                        <img src={require('../../assets/images/logo.png')} alt="Logo" className="logo" />
                        <p className="message">Thank you very much for completing the first stage of our survey.</p>
                        <p className="question">
                            Would you like to provide any feedback?
                            <span className="disclaimer">(Optional)</span>
                        </p>
                        <textarea rows="5" onInput={(e) => setText(e.target.value)} value={text}></textarea>
                        <button>Enviar</button>
                    </form>

                    <div className={modalStep === 3 ? 'nps_success active' : 'nps_success'}>
                        <img src={require('../../assets/images/logo-large.png')} alt="Logo" className="logo" />
                        <span className="border"></span>
                        <h2>Success!</h2>
                        <p>Your comment has been successfully submitted!</p>
                    </div>
                </>
            )}

            {!canRender && (
                <div className="nps_loading active">
                    <img src={require('../../assets/images/logo.png')} alt="Logo" />
                    <span className="border"></span>
                    <div className="loading">
                        <img src={require('../../assets/images/loading.gif')} alt="Loading" />
                    </div>
                </div>
            )}
        </>
    );
};

export default NPS;
