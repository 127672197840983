import { useEffect, useState } from 'react';
import './index.scss';
import axios from 'axios';

//? Images
import naught from '../../assets/images/nps/0.png';
import one from '../../assets/images/nps/1.png';
import two from '../../assets/images/nps/2.png';
import three from '../../assets/images/nps/3.png';
import four from '../../assets/images/nps/4.png';
import five from '../../assets/images/nps/5.png';
import six from '../../assets/images/nps/6.png';
import seven from '../../assets/images/nps/7.png';
import eight from '../../assets/images/nps/8.png';
import nine from '../../assets/images/nps/9.png';
import ten from '../../assets/images/nps/10.png';
import Swal from 'sweetalert2';

const NPSv2 = () => {
    const [hash, setHash] = useState(null);
    const [comment, setComment] = useState('');

    const [canRender, setCanRender] = useState(false);

    const [modalStep, setModalStep] = useState(1);

    const evalList = [
        {
            content: '0',
            image: naught,
        },
        {
            content: '1',
            image: one,
        },
        {
            content: '2',
            image: two,
        },
        {
            content: '3',
            image: three,
        },
        {
            content: '4',
            image: four,
        },
        {
            content: '5',
            image: five,
        },
        {
            content: '6',
            image: six,
        },
        {
            content: '7',
            image: seven,
        },
        {
            content: '8',
            image: eight,
        },
        {
            content: '9',
            image: nine,
        },
        {
            content: '10',
            image: ten,
        },
    ];

    useEffect(() => {
        const handleLoad = () => {
            if (window.location.hostname !== 'cs.corebiz.ag') return (window.location = 'https://www.corebiz.ag');
            if (!window.location.search) return (window.location = 'https://www.corebiz.ag');

            const urlParams = new URLSearchParams(window.location.search);

            const var_hash = urlParams.get('h');

            setHash(var_hash);

            setCanRender(true);
        };

        if (document.readyState === 'complete') {
            handleLoad();
        } else {
            window.addEventListener('load', handleLoad);
        }

        return () => {
            if (document.readyState !== 'complete') {
                window.removeEventListener('load', handleLoad);
            }
        };
    }, []);

    const sendEvaluate = (evaluate) => {
        Swal.fire({
            title: 'Are you sure?',
            showCancelButton: true,
            confirmButtonText: 'Yes, send it!',
            confirmButtonColor: '#1273e6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                setCanRender(false);

                axios
                    .post('https://cs.corebiz.ag/api/evaluation/nps', {
                        h: hash,
                        e: evaluate,
                    })
                    .then(() => {
                        setCanRender(true);

                        setModalStep(2);
                    })
                    .catch(() => {
                        setCanRender(true);

                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong!',
                        });
                    });
            }
        });
    };

    const sendForm = (e) => {
        e.preventDefault();

        if (comment) {
            setCanRender(false);

            axios
                .put('https://cs.corebiz.ag/api/evaluation/comment_nps', {
                    h: hash,
                    comment: comment,
                })
                .then(() => {
                    setCanRender(true);

                    setModalStep(3);
                })
                .catch(() => {
                    setCanRender(true);

                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                    });
                });
        } else {
            setModalStep(3);
        }
    };

    return (
        <>
            {canRender && (
                <>
                    <form className={modalStep === 1 ? 'npsv2_form active' : 'npsv2_form'}>
                        <img src={require('../../assets/images/logo.png')} alt="Logo" className="logo" />
                        <p className="question">On a scale of 0 to 10, what is the likelihood that you would recommend Corebiz to a friend or family member?</p>
                        <div className="npsv2">
                            {evalList.map((item, index) => (
                                <div className="npsv2_item" key={index} onClick={() => sendEvaluate(item.content)}>
                                    <img src={item.image} alt={item.content} />
                                </div>
                            ))}
                        </div>
                    </form>

                    <form className={modalStep === 2 ? 'npsv2_form active' : 'npsv2_form'} onSubmit={(e) => sendForm(e)}>
                        <img src={require('../../assets/images/logo.png')} alt="Logo" className="logo" />
                        <p className="message">Thank you very much for completing the first stage of our survey.</p>
                        <p className="question">
                            Would you like to provide any feedback?
                            <span className="disclaimer">(Optional)</span>
                        </p>
                        <textarea rows="5" onInput={(e) => setComment(e.target.value)} value={comment}></textarea>
                        <button>Send</button>
                    </form>

                    <div className={modalStep === 3 ? 'npsv2_success active' : 'npsv2_success'}>
                        <img src={require('../../assets/images/logo-large.png')} alt="Logo" className="logo" />
                        <span className="border"></span>
                        <h2>Success!</h2>
                        <p>Your comment has been successfully sent!</p>
                    </div>
                </>
            )}

            {!canRender && (
                <div className="npsv2_loading active">
                    <img src={require('../../assets/images/logo.png')} alt="Logo" />
                    <span className="border"></span>
                    <div className="loading">
                        <img src={require('../../assets/images/loading.gif')} alt="Loading" />
                    </div>
                </div>
            )}
        </>
    );
};

export default NPSv2;
