import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const HeaderSkeleton = () => (
    <div className="approvalPage__container__headerWrapper">
        <span className="approvalPage__container__headerWrapper__approvalName">
            <Skeleton width={200} height={18} />
        </span>
        <div className="approvalPage__container__headerWrapper__approvalInfo">
            <span className="approvalPage__container__headerWrapper__approvalInfo__dateCreated">
                <Skeleton width={320} height={18} />
            </span>
            <span className="approvalPage__container__headerWrapper__approvalInfo__dateExpire">
                <Skeleton width={170} height={18} />
            </span>
        </div>
    </div>
);

const DocumentPDFWrapperSkeleton = () => (
    <div className="approvalPage__container__documentPDFWrapper">
        <Skeleton width="100%" height={480} />
    </div>
);

const FooterWrapperSkeleton = () => (
    <div className="approvalPage__container__footerWrapper">
        <Skeleton width={180} height={40} />
        <Skeleton width={180} height={40} />
    </div>
);

const ApprovalPageSkeleton = () => (
    <>
        <HeaderSkeleton />
        <DocumentPDFWrapperSkeleton />
        <FooterWrapperSkeleton />
    </>
);

export default ApprovalPageSkeleton;
