import { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

import { toCapitalize, formatDate } from './utils';
import ApprovalPageSkeleton from './loading';

import './index.scss';

// URL EXAMPLE:
// https://cs.corebiz.ag/approval/fc43b001cd1c588d699f66fdca2d57ad/123
// https://cs.corebiz.ag/approval/{path}/{userID}

const Approval = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [path, setPath] = useState('');
    const [userID, setUserID] = useState(null);
    const [dateExpired, setDateExpired] = useState('');

    const dateCurrent = new Date();

    const handleApprove = (path, userID) => {
        axios
            .put(`/api/approval/approve/${path}`, {
                approved_by: userID,
            })
            .then(() => {
                GetData(path);
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Approved!',
                    showConfirmButton: false,
                    timer: 2000,
                });
            })
            .catch(() => {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'An error occurred on our servers, please try again',
                    confirmButtonText: 'Close',
                    confirmButtonColor: '#005fd0',
                });
            });
    };

    const handleSendChanges = (path, userID) => {
        Swal.fire({
            title: 'Describe your changes',
            input: 'textarea',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            cancelButtonColor: '#a5a5a5',
            confirmButtonText: 'Submit changes',
            confirmButtonColor: '#005fd0',
            showLoaderOnConfirm: true,
            reverseButtons: true,
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .put(`/api/approval/review/${path}`, {
                        request: result?.value,
                        request_by: userID,
                    })
                    .then(() => {
                        GetData(path);
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Change sent!',
                            showConfirmButton: false,
                            timer: 2000,
                        });
                    })
                    .catch(() => {
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: 'An error occurred on our servers, please try again',
                            confirmButtonText: 'Close',
                            confirmButtonColor: '#005fd0',
                        });
                    });
            }
        });
    };

    const handleViewChanges = (text) => {
        Swal.fire({
            text,
            confirmButtonText: 'Close',
            confirmButtonColor: '#005fd0',
        });
    };

    const GetData = (path) => {
        axios
            .get(`/api/approval/get/${path}`)
            .then((response) => {
                setData(response?.data);
                setLoading(false);

                setDateExpired(new Date(response?.data?.expire_date));
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        const handleLoad = () => {
            if (window.location.hostname !== 'cs.corebiz.ag') return;

            const urlParams = window?.location?.pathname?.split('/').slice(2);

            urlParams.forEach((param, index) => {
                urlParams[index] = param.replace(/%20/g, ' ');
            });

            setPath(urlParams[0]);
            setUserID(urlParams[1] || null);

            GetData(urlParams[0]);
        };

        if (document.readyState === 'complete') {
            handleLoad();
        } else {
            window.addEventListener('load', handleLoad);
        }

        return () => {
            if (document.readyState !== 'complete') {
                window.removeEventListener('load', handleLoad);
            }
        };
    }, []);

    return (
        <div className="approvalPage__container">
            {loading ? (
                <ApprovalPageSkeleton />
            ) : (
                <div>
                    <div className="approvalPage__container__headerWrapper">
                        {data?.status?.toLowerCase() === 'review' && dateExpired >= dateCurrent && (
                            <>
                                <span className="approvalPage__container__headerWrapper__approvalName">{data?.approval_name}</span>
                                <div className="approvalPage__container__headerWrapper__approvalInfo">
                                    <span className="approvalPage__container__headerWrapper__approvalInfo__dateCreated">
                                        Created by {toCapitalize(data?.author_name)} on {formatDate(data?.created_at, ',', 'BR MONTH')}
                                    </span>
                                    <span className="approvalPage__container__headerWrapper__approvalInfo__dateExpire">Due Date: {formatDate(data?.expire_date, '/', 'BR')}</span>
                                </div>
                            </>
                        )}

                        {data?.status?.toLowerCase() === 'requestchanges' && (
                            <>
                                <div className="approvalPage__container__headerWrapper__approvalStatus" style={{ background: '#ffd026' }}>
                                    Changes requested {formatDate(data?.request_at, '/', 'BR')} by {toCapitalize(data?.request_by)}
                                </div>
                            </>
                        )}

                        {data?.status?.toLowerCase() === 'approved' && (
                            <>
                                <div className="approvalPage__container__headerWrapper__approvalStatus" style={{ background: '#0ca01b' }}>
                                    Approval carried out on {formatDate(data?.approved_at, '/', 'BR')} by {toCapitalize(data?.approved_by)}
                                </div>
                            </>
                        )}

                        {dateExpired < dateCurrent && (
                            <>
                                <div className="approvalPage__container__headerWrapper__approvalStatus" style={{ background: '#f24726' }}>
                                    Expired approval request {formatDate(data?.expire_date, '/', 'BR')}
                                </div>
                            </>
                        )}
                    </div>

                    <div className="approvalPage__container__documentPDFWrapper">
                        <iframe className="approvalPage__container__documentPDFWrapper__documentPDF" title="PDF" src={data?.evidence_pdf} />
                    </div>

                    {data?.status?.toLowerCase() === 'review' && dateExpired >= dateCurrent && userID && (
                        <div className="approvalPage__container__footerWrapper">
                            <button className="approvalPage__container__footerWrapper__buttonChanges" onClick={() => handleSendChanges(path, userID)}>
                                Needs Changes
                            </button>
                            <button className="approvalPage__container__footerWrapper__buttonApprove" onClick={() => handleApprove(path, userID)}>
                                Approve
                            </button>
                        </div>
                    )}

                    {data?.status?.toLowerCase() === 'requestchanges' && userID && (
                        <div className="approvalPage__container__footerWrapper changes">
                            <button className="approvalPage__container__footerWrapper__buttonChanges" onClick={() => handleViewChanges(data?.request)}>
                                View Changes
                            </button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Approval;
