import './assets/scss/reset.scss';
import './assets/scss/fonts.scss';
import './index.scss';

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import NPS from './pages/NPS';
import NPSv2 from './pages/NPS v2';
import NPSInsider from './pages/NPS-insider';
import CSAT from './pages/CSAT';
import CSATv2 from './pages/CSAT v2';
import Article from './pages/Article';

import reportWebVitals from './reportWebVitals';
import Approval from './pages/Approval';

const root = ReactDOM.createRoot(document.querySelector("[data-content='page']"));

const RedirectPage = () => {
    if (process.env.NODE_ENV === 'development') {
        return <h1 style={{ color: '#f00', fontSize: '20px', textAlign: 'center', paddingTop: '20px' }}>Development mode</h1>;
    } else {
        return (window.location = 'https://www.corebiz.ag');
    }
};

const ShowVersion = () => {
    useEffect(() => {
        const version = require('../../package.json').version;

        console.log(`%cCorebiz NPS v${version}`, 'color: #f00; font-size: 20px;');
    }, []);
};

root.render(
    <BrowserRouter>
        <ShowVersion />
        <Routes>
            <Route element={<NPS />} path="nps" />
            <Route element={<NPSInsider />} path="nps-insider" />
            <Route element={<CSAT />} path="csat" />
            <Route element={<Article />} path="article/:id" />
            <Route element={<Approval />} path="approval/:path" />
            <Route element={<Approval />} path="approval/:path/:user" />

            {/* EVALUATIONS V2 */}
            <Route element={<NPSv2 />} path="v2/nps" />
            <Route element={<CSATv2 />} path="v2/csat" />

            <Route element={<RedirectPage />} path="*" />
        </Routes>
    </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
