import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';

const Article = () => {
    const [id, setID] = useState('');
    const [url, setUrl] = useState('');
    const [title, setTitle] = useState('');

    useEffect(() => {
        const handleLoad = () => {
            if (window.location.hostname !== 'cs.corebiz.ag') return;

            //? Example: http://localhost:3000/nps/teste/teste@gmail.com/corebiz/1/10
            //? Get the URL parameters (/npns/:nome/:email/:empresa/:tier/:nota)
            const urlParams = window.location.pathname.split('/').slice(2);
            urlParams.forEach((param, index) => {
                urlParams[index] = param.replace(/%20/g, ' ');
            });

            const var_id = urlParams[0];

            window.Intercom('boot', {
                api_base: 'https://api-iam.intercom.io',
                app_id: window.INTERCOM_APP_ID,
            });

            axios
                .get(`/api/article/${var_id}`)
                .then((response) => {
                    const { data } = response;

                    setUrl(data.url);
                    setTitle(data.title);
                    setID(data.id);
                })
                .catch((error) => {
                    alert('Error!');
                    window.close();
                });
        };

        if (document.readyState === 'complete') {
            handleLoad();
        } else {
            window.addEventListener('load', handleLoad);
        }

        return () => {
            if (document.readyState !== 'complete') {
                window.removeEventListener('load', handleLoad);
            }
        };
    }, []);

    useEffect(() => {
        if (id) {
            if (Cookies.get(`save_article_${id}`)) {
                alert('Thank you!');
                window.close();

                return;
            }

            window.Intercom('trackEvent', 'accept-article', {
                id,
                url,
                title,
            });

            setTimeout(() => {
                alert('Thank you!');
                window.close();
            }, 2000);

            Cookies.set(`save_article_${id}`, true, { expires: 2 });
        }
    }, [id]);

    return <>Loading...</>;
};

export default Article;
