import { useEffect, useState } from 'react';
import './index.scss';
import axios from 'axios';

//? Images
import one from '../../assets/images/csat/1.png';
import two from '../../assets/images/csat/2.png';
import three from '../../assets/images/csat/3.png';
import four from '../../assets/images/csat/4.png';
import five from '../../assets/images/csat/5.png';
import Swal from 'sweetalert2';

const CSATv2 = () => {
    const [hash, setHash] = useState('');
    const [nota, setNota] = useState(null);

    const [canRender, setCanRender] = useState(false);

    const [modalStep, setModalStep] = useState(1);

    const evalList = [
        {
            title: 'Muito insatisfeito',
            content: '1',
            image: one,
        },
        {
            title: 'Insatisfeito',
            content: '2',
            image: two,
        },
        {
            title: 'Neutro',
            content: '3',
            image: three,
        },
        {
            title: 'Satisfeito',
            content: '4',
            image: four,
        },
        {
            title: 'Muito satisfeito',
            content: '5',
            image: five,
        },
    ];

    useEffect(() => {
        const handleLoad = () => {
            if (window.location.hostname !== 'cs.corebiz.ag') return (window.location = 'https://www.corebiz.ag');
            if (!window.location.search) return (window.location = 'https://www.corebiz.ag');

            const urlParams = new URLSearchParams(window.location.search);
            const var_hash = urlParams.get('h');
            const var_eval = urlParams.get('e');

            setHash(var_hash);
            setNota(var_eval);

            setCanRender(true);
        };

        if (document.readyState === 'complete') {
            handleLoad();
        } else {
            window.addEventListener('load', handleLoad);
        }

        return () => {
            if (document.readyState !== 'complete') {
                window.removeEventListener('load', handleLoad);
            }
        };
    }, []);

    useEffect(() => {
        if (nota !== null) {
            sendEvaluate(nota);
        }
    }, [nota]);

    const sendEvaluate = (evaluate) => {
        Swal.fire({
            title: 'Tem certeza?',
            showCancelButton: true,
            confirmButtonText: 'Sim, envie!',
            confirmButtonColor: '#1273e6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                setCanRender(false);

                axios
                    .post('https://cs.corebiz.ag/api/evaluation/csat', {
                        h: hash,
                        e: evaluate,
                    })
                    .then(() => {
                        setCanRender(true);

                        setModalStep(2);
                    })
                    .catch(() => {
                        setCanRender(true);

                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong!',
                        });
                    });
            }
        });
    };

    return (
        <>
            {canRender && (
                <>
                    <form className={modalStep === 1 ? 'csatv2_form active' : 'csatv2_form'}>
                        <img src={require('../../assets/images/logo.png')} alt="Logo" className="logo" />
                        <p className="question">Quão satisfeito você está com o produto/ serviço ou projeto nesse período?</p>
                        <div className="csatv2">
                            {evalList.map((item, index) => (
                                <div className="csatv2_item" key={index} onClick={() => sendEvaluate(item.content)}>
                                    <span class="title">{item.title}</span>
                                    <img src={item.image} alt={item.content} />
                                </div>
                            ))}
                        </div>
                    </form>

                    <div className={modalStep === 2 ? 'csatv2_success active' : 'csatv2_success'}>
                        <img src={require('../../assets/images/logo-large.png')} alt="Logo" />
                        <span className="border"></span>
                        <h2>Sucesso!</h2>
                        <p>Sua avaliação foi enviada com sucesso!</p>
                    </div>
                </>
            )}

            {!canRender && (
                <div className="csatv2_loading active">
                    <img src={require('../../assets/images/logo.png')} alt="Logo" />
                    <span className="border"></span>
                    <div className="loading">
                        <img src={require('../../assets/images/loading.gif')} alt="Loading" />
                    </div>
                </div>
            )}
        </>
    );
};

export default CSATv2;
