import { useEffect, useState } from 'react';
import './index.scss';
import Cookies from 'js-cookie';
import axios from 'axios';
import { MD5 } from 'crypto-js';

//? Images
import one from '../../assets/images/csat/1.png';
import two from '../../assets/images/csat/2.png';
import three from '../../assets/images/csat/3.png';
import four from '../../assets/images/csat/4.png';
import five from '../../assets/images/csat/5.png';
import Swal from 'sweetalert2';

const CSAT = () => {
    const [intercomId, setIntercomId] = useState('');
    const [nome, setNome] = useState('');
    const [service_name, setServiceName] = useState('');
    const [email, setEmail] = useState('');
    const [job, setJob] = useState('');
    const [empresa, setEmpresa] = useState('');
    const [tier, setTier] = useState('');
    const [hub, setHub] = useState('');
    const [nota, setNota] = useState(null);
    const [origin, setOrigin] = useState('');

    const [canRender, setCanRender] = useState(false);

    const [modalStep, setModalStep] = useState(1);

    const evalList = [
        {
            title: 'Very unsatisfied',
            content: '1',
            image: one,
        },
        {
            title: 'Unsatisfied',
            content: '2',
            image: two,
        },
        {
            title: 'Neutral',
            content: '3',
            image: three,
        },
        {
            title: 'Satisfied',
            content: '4',
            image: four,
        },
        {
            title: 'Very satisfied',
            content: '5',
            image: five,
        },
    ];

    useEffect(() => {
        const handleLoad = () => {
            if (window.location.hostname !== 'cs.corebiz.ag') return (window.location = 'https://www.corebiz.ag');
            if (!window.location.search) return (window.location = 'https://www.corebiz.ag');

            const urlParams = new URLSearchParams(window.location.search);
            const var_nome = urlParams.get('contact_name').replace(/%20/g, ' ');
            const var_service_name = urlParams.get('service_name').replace(/%20/g, ' ');
            const var_email = urlParams.get('email').replace(/%20/g, ' ');
            const var_job = urlParams.get('job').replace(/%20/g, ' ');
            const var_tier = urlParams.get('tier').replace(/%20/g, ' ');
            var var_empresa = urlParams.get('company').replace(/%20/g, ' ');
            const var_hub = urlParams.get('hub').replace(/%20/g, ' ');
            const var_nota = urlParams.get('eval');
            const var_origin = urlParams.get('origin') ? urlParams.get('origin').replace(/%20/g, ' ') : '';

            //? Only for test
            if (var_empresa.indexOf('Corebiz') > -1) {
                var_empresa = 'Corebiz';
            }

            axios
                .get(`https://cs.corebiz.ag/api/intercom/${var_email}/${var_empresa}`)
                .then((response) => {
                    return response.data;
                })
                .then((data) => {
                    setIntercomId(data.intercom_id);
                    setNome(var_nome);
                    setServiceName(var_service_name);
                    setEmail(var_email);
                    setJob(var_job);
                    setEmpresa(var_empresa);
                    setTier(var_tier);
                    setHub(var_hub);
                    setNota(var_nota);
                    setOrigin(var_origin);

                    window.mixpanel.init('77fb2e20dfe9559d1965e086e9f992cb', { debug: true });
                    window.mixpanel.identify(var_email);
                    window.mixpanel.people.set({
                        $name: var_nome,
                        $email: var_email,
                        $client: var_empresa,
                        $tier: var_tier,
                    });

                    setCanRender(true);
                })
                .catch(() => {
                    window.location = 'https://www.corebiz.ag';
                });
        };

        if (document.readyState === 'complete') {
            handleLoad();
        } else {
            window.addEventListener('load', handleLoad);
        }

        return () => {
            if (document.readyState !== 'complete') {
                window.removeEventListener('load', handleLoad);
            }
        };
    }, []);

    const trackEventIntercom = () => {
        const eventName = origin ? `csat-response-${origin}` : 'csat-response';

        axios.post('https://cs.corebiz.ag/api/trackEvent', {
            id: intercomId,
            eventName: eventName,
        });
    };

    useEffect(() => {
        if (nota !== null) {
            sendEvaluate(nota);
        }
    }, [nota]);

    const sendEvaluate = (evaluate) => {
        Swal.fire({
            title: 'Are you sure?',
            showCancelButton: true,
            confirmButtonText: 'Yes, send it!',
            confirmButtonColor: '#1273e6',
            cancelButtonColor: '#d33',
        }).then((result) => {
            if (result.isConfirmed) {
                const cookieName = MD5(`savecsat_${nome + email + empresa + job + hub + origin}`).toString();

                if (Cookies.get(cookieName)) {
                    setModalStep(2);
                    return;
                }

                const emailsTester = ['gustavo.henrique@corebiz.ag'];
                const eventName = emailsTester.includes(email) ? '[Test] Corebiz CSAT' : 'Corebiz CSAT';

                if (!emailsTester.includes(email)) {
                    trackEventIntercom();
                }

                window.mixpanel.track(
                    eventName,
                    {
                        name: nome,
                        service_name: service_name,
                        email_address: email,
                        job: job,
                        tier: tier,
                        client: empresa,
                        hub: hub,
                        evaluation: parseInt(evaluate),
                        origin: origin,
                    },
                    function () {
                        Cookies.set(cookieName, true, { expires: 2 });

                        setModalStep(2);
                    },
                );
            }
        });
    };

    return (
        <>
            {canRender && (
                <>
                    <form className={modalStep === 1 ? 'csat_form active' : 'csat_form'}>
                        <img src={require('../../assets/images/logo.png')} alt="Logo" className="logo" />
                        <p className="question">How satisfied are you with the product/service or project in the period?</p>
                        <div className="csat">
                            {evalList.map((item, index) => (
                                <div className="csat_item" key={index} onClick={() => sendEvaluate(item.content)}>
                                    <span class="title">{item.title}</span>
                                    <img src={item.image} alt={item.content} />
                                    <span class="value">{item.content}</span>
                                </div>
                            ))}
                        </div>
                    </form>

                    <div className={modalStep === 2 ? 'csat_success active' : 'csat_success'}>
                        <img src={require('../../assets/images/logo-large.png')} alt="Logo" />
                        <span className="border"></span>
                        <h2>Success!</h2>
                        <p>Your evaluation has been successfully submitted!</p>
                    </div>
                </>
            )}

            {!canRender && (
                <div className="csat_loading active">
                    <img src={require('../../assets/images/logo.png')} alt="Logo" />
                    <span className="border"></span>
                    <div className="loading">
                        <img src={require('../../assets/images/loading.gif')} alt="Loading" />
                    </div>
                </div>
            )}
        </>
    );
};

export default CSAT;
